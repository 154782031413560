import { ICON_SORT } from "../../assets/svg";

export default function ProductFilter({ title, filter, setFilter }) {

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value, clear: false });
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <h5 className="all_product_head text-start mb-4">
                    {title}
                </h5>
            </div>
            <div className="d-flex justify-content-between align-items-center gap-3">
                {/* <div className="border-end pe-3 h-auto d-flex align-items-center cursor-pointer" onClick={() => handleChangeFilter({ target: { name: "priceOrder", value: filter?.priceOrder === "asc" ? "desc" : "asc" } })}> */}
                <div className="h-auto d-flex align-items-center cursor-pointer" onClick={() => handleChangeFilter({ target: { name: "priceOrder", value: filter?.priceOrder === "asc" ? "desc" : "asc" } })}>
                    Price <ICON_SORT order={filter?.priceOrder} />
                </div>
                <div className="d-flex gap-2 align-items-center ">
                    <input min={0} value={filter?.minPrice} onInput={(e) => {
                        const newValue = e.target.value.replace(/[^0-9.]/g, "");
                        e.target.value = newValue && parseFloat(newValue) >= 0 ? newValue : "";
                        handleChangeFilter(e);
                    }} placeholder="Min price" name="minPrice" className="form-control" style={{
                        width: 120
                    }} />
                    -
                    <input min={0} value={filter?.maxPrice} onInput={(e) => {
                        const newValue = e.target.value.replace(/[^0-9.]/g, "");
                        e.target.value = newValue && parseFloat(newValue) >= 0 ? newValue : "";
                        handleChangeFilter(e);
                    }} name="maxPrice" placeholder="Max price" className="form-control" style={{
                        width: 120
                    }} />
                </div>
                {(filter?.minPrice || filter?.maxPrice || filter?.priceOrder) ?
                    (
                        <div className="">
                            <button className="btn btn-primary addcart rounded-5"
                                style={{
                                    height: 25,
                                    padding: 0,
                                    width: 25,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "small",
                                }}
                                onClick={() => setFilter({ priceOrder: "", minPrice: "", maxPrice: "" })}>X</button>
                        </div>
                    ) : null}
            </div>
        </div>
    )
}