import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import AbortController from 'abort-controller';
import apiClient from "../../helpers/apiHelper";
import { PRODUCTS } from "../../helpers/urlHelper";
import { logger } from "../../helpers/commonHelper";
import ROUTES from "../../helpers/routesHelper";

export default function ProductSearch({ category = "", callback = () => { }, defaultValue = "", placeholder = "Search for Products Brands and more..." }) {
    const [value, setValue] = useState(defaultValue);
    const [items, setItems] = useState([]);
    const cancelToken = useRef(null);
    const timeoutRef = useRef(null);

    const navigate = useNavigate();

    const handleSearch = ({ search, category }) => {
        logger("Autocomplete ::: ", { search, category });

        // Cancel the previous request if it exists
        if (cancelToken.current) {
            cancelToken.current.abort();
        }
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(async () => {

            // Create a new AbortController
            cancelToken.current = new AbortController();

            try {
                const res = await apiClient.get(PRODUCTS.SEARCH_AUTOCOMPLETE, {
                    params: { search, category },
                    signal: cancelToken.current.signal,  // Use AbortController's signal
                });

                setItems(res.data || []);
            } catch (error) {
                if (error?.name === "AbortError") {
                    logger("Previous request canceled.");
                } else {
                    logger("Error during search autocomplete:", error);
                    // Optional: Handle error (e.g., display notification to the user)
                }
            }
        }, 500)
    };


    const handleSearchCall = ({ value, category }) => {
        callback({ search: value, category: category });
        handleSearch({ search: value, category: category });
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <Autocomplete
            getItemValue={(item) => item.name}
            items={items}
            renderItem={(item, isHighlighted) =>
                <div>
                    <div
                        style={{ background: isHighlighted ? 'lightgray' : 'white', borderBottom: "1px solid #ccc" }}
                        className="d-flex gap-10 px-2 cursor-pointer"
                        onClick={() => {
                            navigate(ROUTES.PRODUCT_DETAIL + "/" + item._id + "?search=" + item.name);
                        }}
                    >
                        <img src={item.featured_image} alt="" style={{ width: "60px", height: "60px" }} />
                        <span>{item.name}</span>
                    </div>
                </div>
            }
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                handleSearchCall({ value: e.target.value, category });
            }}
            // onSelect={(value) => {
            // logger("called")
            // setValue(value);
            // handleSearchCall({ value, category });
            // }}
            inputProps={{ placeholder, className: "form-control" }}
            wrapperProps={{ className: "auto-complete-input" }}
        />
    );
}
