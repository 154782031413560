import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { APP_NAME } from "../../config/constants";
import { handlePageClick } from "../../helpers/commonHelper";
import { apiGetProductDetail, apiGetTopRankingProducts } from "../../store/products/actions";
import ProductsListingInfinite from "../../Components/Products/ProductsListingInfinite";
import ProductFilter from "./ProductFilter";

const TopRankingProducts = () => {
  let [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { currentCurrency, currentRegion } = useSelector(s => s.config);
  const { detail } = useSelector((s) => s.products.productDetail);
  const { isLoading, items, hasMore, message, skip } = useSelector((s) => s.products.topRankingProducts);
  const [filter, setFilter] = useState({
    priceOrder: "",
    minPrice: "",
    maxPrice: "",
    clear: true,
  });

  const limit = 32;

  const fetchRecords = (init = false) => {
    dispatch(
      apiGetTopRankingProducts({
        limit: limit,
        skip: init ? 1 : skip + 1,
        category: searchParams.get("category"),
        search: searchParams.get("search"),
        filter: {
          priceOrder: filter?.priceOrder,
          minPrice: filter?.minPrice,
          maxPrice: filter?.maxPrice
        }
      })
    );
  }

  useEffect(() => {
    fetchRecords(true);
  }, [searchParams, currentRegion?.region]);
  // }, [searchParams, currentCurrency?.code]);

  useEffect(() => {
    const timout = setTimeout(() => {
      if (!filter?.clear) {
        fetchRecords(true);
      }
    }, 1200);

    return () => clearTimeout(timout);
  }, [filter]);

  useEffect(() => {
    const topIds = searchParams.get("topIds");
    if (topIds) {
      dispatch(apiGetProductDetail({ id: topIds }));
    }
  }, [dispatch, searchParams]);

  return (
    <div className="wrapList">
      <Helmet>
        <title>{APP_NAME} | Shop</title>
      </Helmet>
      <Container fluid>
        <Row>
          {/* <Col lg={3}>
            <Sidefilleter />
          </Col> */}

          <Col lg={12}>
            <ProductFilter
              title={"Top Ranking Products"}
              filter={filter}
              setFilter={setFilter} />
            <ProductsListingInfinite
              {...{
                items:
                  items?.length <= limit && detail
                    ? [
                      ...[
                        detail,
                        ...items?.filter((i) => i._id !== detail._id),
                      ],
                    ]
                    : items,
                isLoading,
                message,
                hasMore,
                fetchRecords,
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopRankingProducts;
