import { createSlice } from '@reduxjs/toolkit'
import { apiGetReviews } from './actions'
import { paginateFulfilled, paginatePending, paginateRejected, paginationInitialState } from '../../helpers/reduxHelper';

const initialState = {
    list: paginationInitialState,
}

export const slice = createSlice({
    name: 'review',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get products
        builder
            .addCase(apiGetReviews.fulfilled, paginateFulfilled('list'))
            .addCase(apiGetReviews.pending, paginatePending('list'))
            .addCase(apiGetReviews.rejected, paginateRejected('list'));
    },
})

export const { manageProductForCart, clearProductList } = slice.actions

export default slice.reducer