import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiChatClient, apiGet, apiPost } from "../../helpers/apiHelper";
import { CHAT } from "../../helpers/urlHelper";
import { logger } from "../../helpers/commonHelper";
import { generatePath } from "react-router-dom";


export const apiGetChatRoom = createAsyncThunk(
  "apiGetChatRoom",
  async ({ data, callback }, Thunk) => {
    try {
      logger("CHAT_ACTIONS", CHAT.CREATE_ROOM);
      const res = await apiChatClient.get(generatePath(CHAT.CREATE_ROOM, { productId: data.productId, chatRoomId: data?.chatRoomId || "" }));
      if (res.status === "success") {
        callback(res);
        return res;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return Thunk.rejectWithValue(
        error.message || "Something went wrong, please try again later."
      );
    }
  }
);

export const apiGetPreviousChatMessages = createAsyncThunk(
  "apiGetPreviousChatMessages",
  async ({ chatRoomId, data, callback }, Thunk) => {
    try {
      logger("CHAT_ACTIONS", CHAT.CREATE_ROOM);
      const res = await apiChatClient.get(generatePath(CHAT.PREVIOUS_MESSAGES, { chatRoomId }), { params: data });
      if (res.status === "success") {
        callback(res);
        return res;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return Thunk.rejectWithValue(
        error.message || "Something went wrong, please try again later."
      );
    }
  }
);