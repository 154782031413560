import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { UncontrolledDropdown, DropdownToggle } from "reactstrap";
import ROUTES from "../../helpers/routesHelper";
import { apiGetCartCount } from "../../store/cart/actions";
import { ICON_CART, ICON_USER_SECONDARY } from "../../assets/svg";
import { formatNumber } from "../../helpers/commonHelper";
import { Dropdown, DropdownMenu, NavDropdown } from "react-bootstrap";
import Accountaside from "../../Pages/Myaccount/Accountaside";
import { apiLogout } from "../../store/auth/actions";

export default function UserAuthCard() {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((s) => s.auth);
  const cartItems = useSelector((s) => s.cart.count);

  useEffect(() => {
    if (isLogin) {
      dispatch(apiGetCartCount());
    }
  }, [dispatch, isLogin]);

  return (
    <div className="user_card">
      <div className="cardone_wallet align-self-center">
        {isLogin ? (
          <Link to="/cart" className="d-flex align-items-center">
            <span className="me-2">{ICON_CART}</span>
            <div className="card_content w-auto">
              <h5 style={{ whiteSpace: "nowrap" }}>Cart</h5>
              {/* <h5>$ 150,000</h5> */}
              {cartItems ? (
                <small
                  className="text-theme-secondary"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {formatNumber(cartItems)} Item{cartItems > 1 ? "s" : ""}
                </small>
              ) : null}
            </div>
          </Link>
        ) : (
          <Link to={ROUTES.LOGIN} className="d-flex align-items-center">
            <span className="me-2">{ICON_USER_SECONDARY}</span>
            <div className="card_content w-auto pe-3">
              <h5 style={{ whiteSpace: "nowrap" }}>Sign in</h5>
            </div>
          </Link>
        )}
      </div>

      <div className="dividerline_verticle"></div>
      <div className="card_user align-self-center">
        {isLogin ? (
          <>
            {/* <NavDropdown
              id="nav-dropdown-dark-example"
              title="Dropdown"
              menuVariant="dark"
            >Also define the standard property 'line-clamp' for compatibilitycss(vendorPrefix)
The -webkit-line-clamp CSS property allows limiting of the contents of a block container to the specified number of lines.

Syntax: none | <integer>
              <NavDropdown.Item><Link to={ROUTES.PROFILE}>Profile</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to={ROUTES.CHANGE_PASSWORD}>Change Password</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to={ROUTES.MY_ORDERS}>My orders</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to={ROUTES.ORDER_ADDRESS}>Address</Link></NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                dispatch(apiLogout());
              }} className="logout-button logout-button-header hover:text-[#fff]" style={{ padding: "10px 20px" }}>Logout</NavDropdown.Item>
            </NavDropdown> */}
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle id="dropdown-basic" className="p-0">
                {/* <Link to="/my-orders"> */}
                <div className="d-flex align-items-center">
                  <span className="me-2">{ICON_USER_SECONDARY}</span>
                  <div className="card_content w-auto">
                    <h5 style={{ whiteSpace: "nowrap" }}>My Account</h5>
                  </div>
                </div>
                {/* </Link> */}
              </Dropdown.Toggle>

              <DropdownMenu className="header-menu">
                <Dropdown.Item><Link to={ROUTES.PROFILE}>Profile</Link></Dropdown.Item>
                <Dropdown.Item><Link to={ROUTES.CHANGE_PASSWORD}>Change Password</Link></Dropdown.Item>
                <Dropdown.Item><Link to={ROUTES.MY_ORDERS}>My orders</Link></Dropdown.Item>
                <Dropdown.Item><Link to={ROUTES.ORDER_ADDRESS}>Address</Link></Dropdown.Item>
                <Dropdown.Item onClick={() => {
                  dispatch(apiLogout());
                }} className="logout-button logout-button-header hover:text-[#fff]" style={{ padding: "10px 20px" }}>Logout</Dropdown.Item>
                {/* <Button
                className="logout-button"
                onClick={() => {
                  dispatch(apiLogout());
                }}
              >
                Logout
              </Button> */}
              </DropdownMenu>
            </Dropdown>
          </>
        ) : (
          <Link to={ROUTES.SIGNUP} className="d-flex align-items-center">
            <span className="me-2">{ICON_USER_SECONDARY}</span>
            <div className="card_content w-auto">
              <h5 style={{ whiteSpace: "nowrap" }}>Sign up</h5>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}
