import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SocketContext from './SocketContext';
import { io } from 'socket.io-client';
import { logger } from '../helpers/commonHelper';
import { getAuthToken } from '../helpers/authHelper';
import { CHAT_SERVICE_URL } from '../config/constants';

export default function SocketProvider({ children }) {
    const [socket, setSocket] = useState(null);
    const { isLogin } = useSelector((s) => s.auth);

    useEffect(() => {
        logger("SOCKET_PROVIDER", "On mount", isLogin);
        if (isLogin) {
            setSocket(io(CHAT_SERVICE_URL, {
                auth: {
                    token: getAuthToken(),
                }
            }));
        }

        return () => {
            if (socket) {
                socket?.off();
                socket?.close();
                socket?.disconnect();
            }
        }
    }, [isLogin]);


    useEffect(() => {
        if (socket) {
            socket.on("connect", (data) => {
                logger("SOCKET_PROVIDER", "Connected to socket server", data);
            });

            socket.on("connect_error", (data) => {
                logger("SOCKET_PROVIDER", "Connection error", data);
            });

            socket.on("disconnect", (data) => {
                logger("SOCKET_PROVIDER", "Disconnected from socket server", data);
            });
        }
    }, [socket]);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
}
