import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import MobileNumberField, { MobileError } from "../../Components/Common/MobileNumberField";
import { apiUpdateProfile, apiVerifyMobile } from "../../store/auth/actions";
import ButtonLoader from "../../Components/Common/ButtonLoader";
import { requiredMobileNumber } from "../../utils/validator";

const ChangeMobileNumber = ({ changeProfile }) => {
  const dispatch = useDispatch();
  const initialValues = {
    countryCode: "",
    mobileNumber: "",
  };

  const validationSchema = Yup.object().shape({
    mobileNumber: requiredMobileNumber({ field: "Mobile number" }),
    countryCode: Yup.string().matches(
      /^\+\d+$/,
      "Country code must start with a '+' and contain only digits"
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data, form) => {
        dispatch(
          apiUpdateProfile({
            data: {
              ...data,
              type: "mobile",
            },
            callback: (res) => {
              changeProfile();
            },
          })
        ).then(({ payload }) => {
          if (typeof payload === "string") {
            toast.error(payload);
          }
          form.setSubmitting(false);
        });
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(form) => {
        return (
          <>
            <Form>
              <div className="text-start my-3">
                <Row>
                  <Col md={6} className="my-2">
                    <div className="position-relative signupinput_phone">
                      <label htmlFor="mobileNumber" className="required">
                        Mobile Number
                      </label>
                      <MobileNumberField
                        className="border rounded"
                        callback={(code, number) => {
                          form.setFieldValue("countryCode", code);
                          form.setFieldValue("mobileNumber", number);
                          form.setFieldTouched("mobileNumber", true);
                        }}
                      />
                      <ErrorMessage name="mobileNumber" className="text-danger"
                        component={"p"} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="my-2 pt-4">
                    <Button type="submit" className="track_order w-100" disabled={form.isSubmitting || form.isValidating}>
                      {form.isSubmitting ? <ButtonLoader size={20} /> : "Update Mobile Number"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ChangeMobileNumber;
