import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ButtonLoader from "../Common/ButtonLoader";
import { apiAddReview } from "../../store/review/actions";

export default function RatingPopup({ show, setShow, detail, reviewAddedCallback }) {
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);

  const handleOnSubmit = (data, form) => {
    dispatch(apiAddReview({
      productId: detail?._id, data
    })).then(({ payload }) => {
      if (payload?.status === "success") {
        reviewAddedCallback();
      }
      form.setSubmitting(false);
    });
  }

  return (
    <>
      {detail?.isItPlacedOrder ? (
        <div className="review-form-wrapper">
          <Formik
            initialValues={{
              review: "",
              rating: 1,
            }}
            onSubmit={handleOnSubmit}
            validationSchema={ratingValidationSchema}
            >

            {({ values, setFieldValue, isSubmitting }) => (
              <Form className="rating-form p-3">
                {/* {JSON.stringify(values)}
                  <Field type="radio" name="rating" value="1" />
                  <Field type="radio" name="rating" value="2" />
                  <Field type="radio" name="rating" value="3" />
                  <Field type="radio" name="rating" value="4" />
                  <Field type="radio" name="rating" value="5" /> */}
                  <h6>Add Rating</h6>

                <div className="rating-stars mb-2">
                  {[1, 2, 3, 4, 5].map((star, index) => (
                    <span
                    key={star}
                    className={`star ${values.rating >= star ? "filled" : ""}`}
                    onClick={() => setFieldValue("rating", star)}
                    style={{ cursor: "pointer" }}
                    >
                      &#9733;
                    </span>
                  ))}
                </div>

                <Field as="textarea" placeholder="Write your review..." name="review" className="form-control review-textarea" />
                <ErrorMessage
                  name="review"
                  className="text-danger"
                  component={"p"}
                />

                <div className="mt-3">
                  <Button
                    className="auth_btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <ButtonLoader /> : "Submit"}
                  </Button>
                </div>
              </Form>
            )}

          </Formik>
        </div>
      ) : null}
    </>
  );
}


const ratingValidationSchema = Yup.object().shape({
  review: Yup.string().required("Please add product review"),
});