import React from "react";
import { Link } from "react-router-dom";
import { Button, FormGroup } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import ROUTES from "../../helpers/routesHelper";
import { apiForgotPassword } from "../../store/auth/actions";
import { ICON_USER } from "../../assets/svg";
import ButtonLoader from "../../Components/Common/ButtonLoader";

export default function SendOtpComponent({ setEmailOtpSent }) {
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const onSubmit = (data, form) => {
    dispatch(
      apiForgotPassword({
        data,
        callback: (res) => {
          form.setSubmitting(false);
          if (res?.status === "success") {
            toast.success(res.message);
            setEmailOtpSent(true);
          }
        },
      })
    ).then(({ payload }) => {
      if (typeof payload === "string") {
        toast.error(payload);
      }
      form.setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(form) => {
        return (
          <Form>
            <FormGroup className="position-relative signupinput_phone mb-3">
              <label htmlFor="email">Email ID</label>
              <div
                className="d-flex align-items-center iconWithText position-relative ps-5"
                style={{
                  background: "var(--theme-color)",
                  borderRadius: "15px",
                  overflow: "hidden",
                }}
              >
                <span className="icn position-absolute" style={{ left: 14 }}>
                  {ICON_USER}
                </span>
                <Field
                  className="form-control send-otp-email ps-0"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
              </div>
              <ErrorMessage
                name="email"
                className="text-danger"
                component={"p"}
              />
            </FormGroup>

            <div className="create_account text-center">
              <p>
                <Link to={ROUTES.LOGIN}>BACK TO LOGIN</Link>
              </p>
            </div>

            <div className="mt-5">
              <Button className="auth_btn" type="submit" disabled={form.isSubmitting}>
                {form.isSubmitting ? <ButtonLoader /> : "Get OTP"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
