import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import { ErrorMessage } from "formik";

const LocationSelector = ({
    type = "country",
    countryId = "",
    stateId = "",
    id,
    name,
    value = "",
    defaultValue = null,
    onChange,
    label,
    required = false,
    placeholder = "Select",
}) => {
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(value || "");

    useEffect(() => {
        // Populate options based on the type
        let newOptions = [];
        if (type === "country") {
            newOptions = Country.getAllCountries();
        } else if (type === "state") {
            newOptions = State.getStatesOfCountry(countryId);
        } else if (type === "city") {
            newOptions = City.getCitiesOfState(countryId, stateId);
        }
        setOptions(newOptions);
    }, [type, countryId, stateId]);


    useEffect(() => {
        // Keep selectedValue in sync with the value prop
        if (defaultValue) {
            const selectedOption = options.find((option) => option.isoCode === defaultValue || option.name === defaultValue);
            if (selectedOption) {
                setSelectedValue(selectedOption?.isoCode || selectedOption?.name);
                onChange({ name: selectedOption?.name, id: selectedOption?.isoCode });
            }
        }
    }, [defaultValue, options, type]);

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedValue(selectedValue);

        // Find the selected object and pass it to the onChange handler
        const selectedOption = options.find(
            (option) => option.isoCode === selectedValue || option.name === selectedValue
        );
        if (selectedOption) {
            onChange({ name: selectedOption?.name, id: selectedOption?.isoCode });
        }
        else {
            onChange(null);
        }
    };

    return (
        <div className="mb-3">
            <label htmlFor={id} className={required ? "required" : ""}>{label}</label>
            <select
                id={id}
                name={name}
                value={selectedValue}
                onChange={handleChange}
                className="form-control"
            >
                <option value="">
                    {placeholder}
                </option>
                {options.map((option) => (
                    <option
                        key={option.isoCode || option.name}
                        value={option.isoCode || option.name}
                    >
                        {option.name}
                    </option>
                ))}
            </select>
            <ErrorMessage className="text-danger" component={"p"} name={name} />
        </div>
    );
};

export default LocationSelector;
