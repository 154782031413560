import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import googleStoreimg from '../../assets/images/google-play.png'

import ROUTES from "../../helpers/routesHelper";
import { Fb_icon, Google_icon, Instagram_icon, TikTok_icon, Twitter_icon, YouTube_icon } from "../../assets/svg";
import apiClient from "../../helpers/apiHelper";
import { EMAIL_SUBSCRIBE } from "../../helpers/urlHelper";
import { logger } from "../../helpers/commonHelper";

export default function Footer({ className = "" }) {
  const { level1 } = useSelector(
    (s) => s.categories.categories
  );

  const initalValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  const onSubmit = async (data, { setSubmitting, resetForm }) => {
    const response = await apiClient.post(EMAIL_SUBSCRIBE.ADD, data);
    logger(response);

    if (response.status === "success") {
      setSubmitting(false);
      resetForm();
      toast.success(response.message);
    }
    else {
      toast.error(response.message || "Something went wrong");
    }
  };

  return (
    <>
      <section className={`footer-main  position-relative ${className}`}>
        <Container>
          <Row>
            {/* <div className="subscriber">
              <h4 className="text-center "></h4>

            </div> */}

            <div className="footer_column text-start">
              <ul className="d-flex align-items-start justify-content-between flex-wrap mb-5">
                <li>
                  <h5>About</h5>
                  <p>
                    Uzabulk is your trusted partner in global trade. We specialize in <strong>global sourcing for buyers and manufacturers</strong>, empowering businesses to access quality products worldwide. For manufacturers, we facilitate <strong>intra-African trade and exports</strong>, connecting you to new markets. With our end-to-end <strong>logistics and handling solutions</strong>, we make doing business easy, efficient, and hassle-free. Let Uzabulk simplify your trade journey—locally and globally.
                  </p>

                </li>
                {/* <li>
                  <div>
                    <h5>Categories</h5>

                    <div className="d-flex flex-column gap-10 mt-3">
                      {level1?.map((value, index) => {
                        if (index < 3)
                          return (
                            <Link
                              key={index}
                              className="text-light text-decoration-none"
                              to={`${ROUTES.PRODUCT_LISTING}?skip=1&category=${value._id}&name=${value.catName}`}>{value.catName}
                            </Link>
                          );
                        return null
                      })}
                    </div>
                  </div>
                </li> */}

                <li>
                  <h5>Make money with Uzabulk</h5>
                  <div>
                    <Link className="text-light text-decoration-none" to={ROUTES.VENDOR_REGISTRATION}>Merchant Signup</Link>
                  </div>
                </li>

                <li>
                  <h5>Links</h5>
                  <div>
                    <Link className="text-light text-decoration-none" to={ROUTES.BLOG}>Blog</Link>
                  </div>
                  <div>
                    <Link className="text-light text-decoration-none" to={ROUTES.ABOUT_US}>About</Link>
                  </div>
                  <div>
                    <Link className="text-light text-decoration-none " to={ROUTES.CART}>Cart</Link>
                  </div>
                  <div>
                    <Link className="text-light text-decoration-none" to={ROUTES.PRODUCT_LISTING}>Products</Link>
                  </div>
                  <div>
                    <Link className="text-light text-decoration-none" to={ROUTES.T_AND_C}>Term and conditions</Link>
                  </div>
                </li>
                {/* <li>
                  <h5>Payments</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo, sed!</p>
                </li> */}
                <li className="subscription-wrapper">
                  <h5 className="mb-3">Subscribe to get our updates</h5>
                  <Formik initialValues={initalValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting, errors, touched }) => (
                      <Form>
                        <div className="d-flex flex-column gap-2 footer_subscription-input">
                          <Field
                            className={touched?.email && errors?.email ? "w-100 rounded-2 is-error" : "w-100 rounded-2"}
                            type="email"
                            name="email"
                            id="emailid"
                            placeholder="Enter email address"
                          />
                          <Button type="submit" disabled={isSubmitting} className="subscribe_btn w-100 rounded-2">Subscribe</Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </li>

              </ul>
              <ul>
                <li>
                  <h5>
                    GET THE APP
                  </h5>
                </li>
                <li>
                  <div className="d-flex gap-3 mt-3">
                    <a href="#">
                      <Google_icon />
                    </a>
                    <a href="https://www.facebook.com/Uza.solutions/">
                      <Fb_icon />
                    </a>
                    <a href="https://x.com/uza_solutions">
                      <Twitter_icon />
                    </a>
                    {/* <a href="#">
                      <YouTube_icon />
                    </a> */}
                    <a href="https://www.instagram.com/uza_solutions/">
                      <Instagram_icon />
                    </a>
                    <a href="https://www.tiktok.com/@uza.solutions?_t=ZM-8rvftFDNZDE&_r=1">
                      <TikTok_icon />
                    </a>
                  </div>
                </li>
              </ul>
            </div>

          </Row>
        </Container>
      </section>
    </>
  );
}
