// src/apiHelper.js
import axios from "axios";
import { toast } from "react-toastify";
import { getAuthToken, removeAuthInfo } from "./authHelper";
import ROUTES from "./routesHelper";
import { logger } from "./commonHelper";
import { getCurrencySymbol, getRegion } from "./currencyHelper";
import { CHAT_SERVICE_URL } from "../config/constants";

// Create an instance of axios with default settings
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api/v1", // Replace with your API base URL
  timeout: 300000, // 5 minutes timeout
  headers: {
    "Content-Type": "application/json",
  },
});

const apiChatClient = axios.create({
  baseURL: CHAT_SERVICE_URL + "/api/v1", // Replace with your API base URL
  timeout: 300000, // 5 minutes timeout
  headers: {
    "Content-Type": "application/json",
  },
});

const responseCallback = (response) => response.data || null
const responseErrorCallback = (error) => {
  logger("ERROR RESPONSE ::: ", error);
  // Handle response errors
  if (error.response && error.response.status === 401) {
    // Handle unauthorized error (e.g., redirect to login)
    removeAuthInfo();
    window.location.href = ROUTES.LOGIN;
  }
  else {
    let message = error.response?.data?.message ||
      error?.message ||
      "Something went wrong, please try again later.";
    // if (message !== "CANCELED_REQUEST") {
    //   toast.error(message);
    // }
  }
  return Promise.reject(error?.response?.data);
}
const requestCallback = (config) => {
  // Modify the request config before sending the request
  const token = getAuthToken(); // Example: Get token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Accept-Currency"] = getCurrencySymbol(); // Set the custom header for currency
  const region = getRegion();
  if (region !== "all")
    config.headers["x-country"] = region; // Set the custom header for currency
  return config;
}
const requestErrorCallback = (error) => {
  // Handle request errors
  return Promise.reject(error);
}

// Response interceptor (for handling responses and errors globally)
apiClient.interceptors.response.use(responseCallback, responseErrorCallback);
apiClient.interceptors.request.use(requestCallback, requestErrorCallback);
apiChatClient.interceptors.response.use(responseCallback, responseErrorCallback);
apiChatClient.interceptors.request.use(requestCallback, requestErrorCallback);

// Basic functions for making API calls
export const apiGet = async (url, params = {}) => {
  return apiClient.get(url, { params });
};

export const apiPost = async (url, data = {}) => {
  return apiClient.post(url, data);
};

export const apiPut = async (url, data = {}) => {
  return apiClient.put(url, data);
};

export const apiDelete = async (url) => {
  return apiClient.delete(url);
};

export { apiChatClient };

// Export the apiClient if you need to use it directly for advanced use cases
export default apiClient;
