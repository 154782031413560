import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../helpers/routesHelper";
import { apiGetCurrencies, apiGetRegions } from "../store/config/actions";

// importing all the themes

import BaseTheme from "../Themes/BaseTheme";
import PublicRoutesTheme from "../Themes/PublicRoutesTheme";
import PrivateRoutesTheme from "../Themes/PrivateRoutesTheme";

import Pagenotfound from "../Pages/404page";
import Congrats from "../Pages/Congrats";
import Cartpage from "../Pages/Cartpage";
import Checkoutpage from "../Pages/Checkoutpage";
import MyOrdersPage from "../Pages/MyOrders";
import OrderDetailPage from "../Pages/OrderDetail";
import AddressPage from "../Pages/Address";
import AddAddressPage from "../Pages/AddAddress";
import ProfilePage from "../Pages/Profile";
import ChangePasswordPage from "../Pages/ChangePassword";
import Signin from "../Pages/Signin";
import Signup from "../Pages/Signup";
import ForgotPassword from "../Pages/ForgotPassword";
import Homepage from "../Pages/Home";
import Blog from "../Pages/Blog";
import Products from "../Pages/Products";
import ProductsList from "../Pages/ProductsList";
import TopRankingProducts from "../Pages/ProductsList/TopRankingProducts";
import NewArrivalProducts from "../Pages/ProductsList/NewArrivalProducts";
import SavingSpotlightProducts from "../Pages/ProductsList/SavingSpotlightProducts";
import BestDealProducts from "../Pages/ProductsList/BestDealProducts";
import Singleview from "../Pages/Singleview";
import Myaccount from "../Pages/Myaccount";
import AboutUs from "../Pages/AboutUs";
import ContactUs from "../Pages/ContactUs";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsAndConditions from "../Pages/TermsAndConditions";
import { logger } from "../helpers/commonHelper";
import Loader from "../Components/Common/Loader";
import UploadSlip from "../Pages/UploadSlip";
import MerchantSignup from "../Pages/MerchantSignup";
import MerchantSignupSuccess from "../Pages/MerchantSignup/MerchantSignupSuccess";

export default function MyRouts() {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((s) => s.auth);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // dispatch(apiGetConfigurations());
    dispatch(apiGetCurrencies()).then(({ payload }) => {
      logger("CURRENCY LIST ::: ", payload);
      setIsLoading(false);
    });
    dispatch(apiGetRegions()).then(({ payload }) => {
      logger("REGION LIST ::: ", payload);
      setIsLoading(false);
    });
  }, []);

  // if (isLoading) {
  //   return <Loader />
  // }

  return (
    <div>
      <BrowserRouter>
        <Routes>

          <Route element={<BaseTheme />}>

            <Route element={<PrivateRoutesTheme isLogin={isLogin} />}>
              <Route path={ROUTES.CONGRATULATION} element={<Congrats />} />
              <Route path={ROUTES.CART} element={<Cartpage />} />
              <Route path={ROUTES.CHECKOUT} element={<Checkoutpage />} />

              <Route element={<Myaccount />}>
                <Route path={ROUTES.MY_ORDERS} element={<MyOrdersPage />} />
                <Route path={ROUTES.ORDER_DETAIL + "/:id"} element={<OrderDetailPage />} />
                <Route path={ROUTES.ORDER_ADDRESS} element={<AddressPage />} />
                <Route path={ROUTES.CREATE_ADDRESS + "/:id"} element={<AddAddressPage />} />
                <Route path={ROUTES.CREATE_ADDRESS + "/"} element={<AddAddressPage />} />
                <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
                <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
              </Route>
            </Route>

            <Route element={<PublicRoutesTheme isLogin={isLogin} />}>
              <Route path={ROUTES.LOGIN} element={<Signin />} />
              <Route path={ROUTES.SIGNUP} element={<Signup />} />
              <Route path={ROUTES.FORGOT} element={<ForgotPassword />} />
            </Route>

            <Route path={ROUTES.VENDOR_REGISTRATION} element={<MerchantSignup />} />
            <Route path={ROUTES.VENDOR_REGISTRATION_SUCCESS} element={<MerchantSignupSuccess />} />
            <Route path={ROUTES.HOME} element={<Homepage />} />
            <Route path={ROUTES.BLOG} element={<Blog />} />
            <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
            <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
            <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={ROUTES.T_AND_C} element={<TermsAndConditions />} />
            <Route path={ROUTES.CATEGORIES} element={<Products />} />
            <Route path={ROUTES.PRODUCT_LISTING} element={<ProductsList />} />
            <Route path={ROUTES.TOP_RANKING_PRODUCT_LISTING} element={<TopRankingProducts />} />
            <Route path={ROUTES.NEW_ARRIVALS_PRODUCT_LISTING} element={<NewArrivalProducts />} />
            <Route path={ROUTES.SAVING_SPOTLIGHT_PRODUCT_LISTING} element={<SavingSpotlightProducts />} />
            <Route path={ROUTES.BEST_DEAL_PRODUCT_LISTING} element={<BestDealProducts />} />
            <Route path={`${ROUTES.PRODUCT_DETAIL}/:id`} element={<Singleview />} />
            {/* <Route path="/all-categories" element={<Allcaegoriestheme/>} /> */}
            <Route path={ROUTES.UPLOAD_SLIP} element={<UploadSlip />} />

          </Route>

          <Route path={ROUTES.PAGE_NOT_FOUND} element={<Pagenotfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
