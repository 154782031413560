import { defaultExchangeRate, defaultRegion } from "../config/constants";

const CURRENCY_SYMBOL = "uza-currency-symbol-latest";
const UZA_REGION = "uza-customer-region";


export const getCurrencySymbol = () => {
    // return localStorage.getItem(CURRENCY_SYMBOL) || defaultExchangeRate.code;
    return defaultExchangeRate.code;
};

export const setCurrencySymbol = (symbol) => {
    localStorage.setItem(CURRENCY_SYMBOL, symbol);
}

export const getRegion = () => {
    return localStorage.getItem(UZA_REGION) || defaultRegion.region;
};

export const setRegion = (symbol) => {
    localStorage.setItem(UZA_REGION, symbol);
}