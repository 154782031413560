import React from "react";
import { useDropzone } from "react-dropzone";
import apiClient from "../../helpers/apiHelper";
import { FILE } from "../../helpers/urlHelper";
import { logger } from "../../helpers/commonHelper";
import { ICON_IMG, ICON_PDF } from "../../assets/svg";
import { Link } from "react-router-dom";

const FileDropzone = ({ onDrop, files }) => {
    // Initialize useDropzone at the top level
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "image/*": [".png", ".jpg", ".jpeg"],
        },
        onDrop: async (acceptedFiles) => {
            try {
                const formData = new FormData();

                // Append each file to the FormData object
                acceptedFiles.forEach((file) => {
                    formData.append('file', file);
                });

                // Call the file upload API
                const response = await apiClient.post(FILE.ADD, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Handle the response
                if (response.status == "success") {
                    logger('Upload success:',);
                    onDrop(response?.data?.link);
                } else {
                    logger('Upload failed:', response.message);
                }
            } catch (err) {
                logger('Error uploading file:', err);
            }
        },
    });

    return (
        <div {...getRootProps()} className="dropzone uza-dropzone">
            <input {...getInputProps()} />
            <p>Drag & drop files here, or click to select files</p>
            <div className="file-preview">
                {files.map((file, index) => {
                    const ext = ((file?.split("."))?.at(-1))?.toLowerCase();
                    return (
                        <div key={index} onClick={(event) => {
                            event.stopPropagation();
                        }}>
                            <Link to={file} target="_blank" className="border d-flex file-item flex-wrap justify-content-center p-2 rounded">
                                {ext != "pdf" ? <img src={file} alt="" className="img-fluid" style={{
                                    width: 50,
                                    height: 50,
                                }} /> : ICON_PDF()}
                                {/* {ext != "pdf" ? ICON_IMG() : ICON_PDF()} */}
                                <p style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    height: "25px",
                                    marginTop: "3px",
                                    textDecoration: "none",
                                }}>{(file?.split("/")).at(-1)}</p>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div >
    );
};

export default FileDropzone;
