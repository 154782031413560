import { CHAT_SERVICE_URL } from "../config/constants";

export const AUTH = {
  VERIFY_EMAIL: "users/verifyEmail",
  VERIFY_MOBILE: "users/verifyMobileNumber",
  VERIFY_OTP: "users/verifyOtp",
  REGISTER: "users/register",
  LOGIN: "users/login",
  LOGOUT: "users/logout",
  VENDOR_SIGNUP: "users/vendorSignup",
};

export const FORGOT_PASSWORD = {
  SEND_OTP: "users/forgotPassword",
  RESET: "users/resetPassword",
};

export const PROFILE = {
  GET: "users/profile",
  UPDATE: "users/update",
  CHANGE_PASSWORD: "users/changePassword",
};

export const CATEGORIES = {
  LIST: "categories/list",
  LIST_BY_LEVEL: "categories/listByLevel",
  TOP_CATEGORIES: "categories/top-cat",
  SOURCE_APPLICATION: "categories/source-application",
};

export const PRODUCTS = {
  SEARCH_AUTOCOMPLETE: "products/searchAutocomplete", // search
  LIST: "products/list", // search
  TOP_RANKING: "products/top-ranking",
  NEW_ARRIVAL: "products/new-arrivals", // search
  SAVING_SPOTLIGHT: "products/savings-spotlight",
  GUARANTED: "products/guaranteed-products",
  DETAIL: "products/view",
  FREQUENTLY_SEARCH: "products/frequentlySearch", // search
};

export const REVIEW = {
  ADD: "products/reviews/:productId/add", // add
  LIST: "products/reviews/:productId/list", // list
};

export const CART = {
  COUNT: "carts/count", // get
  ADD: "carts/add", // post
  LIST: "carts/list", // get
  UPDATE: "carts/update", // put
  DELETE: "carts/remove", // delete
};

export const ORDER = {
  CHECKOUT: "orders/checkout", // post
  PLACE: "orders/add", // post
  LIST: "orders/list", // get
  DETAIL: "orders/view", // get
  CHECK_SLIP_STATUS: "orders/viewOrderDetail", // get
  UPLOAD_SLIP: "orders/uploadSlip", // get
  CREATE_SLIP_UPLOAD_LINK: "orders/createSlipUploadLink",
};

export const ADDRESS = {
  LIST: "addresses/list",
  ADD: "addresses/add",
  VIEW: "addresses/view",
  DELETE: "addresses/delete",
  UPDATE: "addresses/update",
  MAKE_DEFAULT: "addresses/makeDefaultAddress",
};

export const PAGE = {
  ABOUT_US: "pages/aboutUs",
  CONTACT_US: "pages/contactUs",
  PRIVACY_POLICY: "pages/privacyPolicy",
  T_AND_C: "pages/termAndConditions",
};

export const CONFIGURATIONS = {
  GET: "config",
  CURRENCIES: "config/currencies",
  REGIONS: "users/vendor/countries",
};

export const FILE = {
  ADD: "file/add",
};

export const EMAIL_SUBSCRIBE = {
  ADD: "email-subscriber/add",
};


export const CHAT = {
  CREATE_ROOM: "chat/room/:productId/:chatRoomId",
  LIST_ROOMS: "chat/list",
  PREVIOUS_MESSAGES: "chat/previousMessages/:chatRoomId",
  DELETE_ROOM: "chat/delete/:chatRoomId",
};