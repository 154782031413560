import { createSlice } from "@reduxjs/toolkit";
import { apiGetChatRoom, apiGetPreviousChatMessages } from "./actions";
import { logger } from "../../helpers/commonHelper";


const initialState = {
  isLoading: false,
  isFetching: false,
  chatRoom: null,
  product: null,
  chatList: [],
  profile: null,
  hasMoreMessages: false,
};

export const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    clearChatRoom: (state, action) => {
      state = initialState;
    },
    newMessageReceived: (state, action) => {
      logger("newMessageReceived", action.payload)
      state.chatList = [action.payload, ...state.chatList];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(apiGetChatRoom.pending, (state, action) => {
        state.isLoading = true;
        state.isFetching = true;
      })
      .addCase(apiGetChatRoom.rejected, (state, action) => {
        state.isLoading = false;
        state.isFetching = false;
      })
      .addCase(apiGetChatRoom.fulfilled, (state, action) => {
        logger("CHAT_SLICE", action.payload)
        state.isLoading = false;
        state.isFetching = false;
        state.chatRoom = action.payload?.data || null;
        state.product = action.payload?.product || null;
        state.chatList = action.payload?.messages || [];
        state.profile = action.payload?.profile || null;
        state.hasMoreMessages = action.payload?.hasMoreMessages || false;
      });

    builder
      .addCase(apiGetPreviousChatMessages.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(apiGetPreviousChatMessages.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(apiGetPreviousChatMessages.fulfilled, (state, action) => {
        const { data, hasMoreMessages } = action.payload;
        state.isFetching = false;
        state.chatList = [...(state?.chatList || []), ...(data || [])];
        state.hasMoreMessages = hasMoreMessages || false;
      });
  },
});

export const { clearChatRoom, newMessageReceived } = slice.actions;

export default slice.reducer;
