const envConfig = process.env;

export const APP_NAME = "UZA Bulk";

export const defaultExchangeRate = { name: "United State Dollar", code: "USD", symbol: "$", imageUrl: "https://uza-ecomm.s3.us-east-2.amazonaws.com/1726035363694united-states.png", };

export const defaultRegion = { region: "all", country: "All" };

export const CHAT_SERVICE_URL = envConfig.REACT_APP_CHAT_SERVICE || "http://localhost:3006";

export const ORDER_STATUS = {
    "pending": {
        label: "Pending",
    },
    "confirmed": {
        label: "Confirmed",
    },
    "ready_for_shipment": {
        label: "Ready for shipment",
    },
    "shipped": {
        label: "Shipped",
    },
    "delayed": {
        label: "Delayed",
    },
    "out_for_delivery": {
        label: "Out for delivery",
    },
    "completed": {
        label: "Completed",
    },
    "rejected": {
        label: "Rejected",
    },
    "cancelled": {
        label: "Cancelled",
    },
    "archived": {
        label: "Archived",
    },
}