import React, { useEffect, Suspense, useDeferredValue } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BestSalerProduct from "./BestSalerProduct";
import ROUTES from "../../helpers/routesHelper";
import { smoothScrollToTop, logger } from "../../helpers/commonHelper";
import { apiGetHomeSavingSpotlightProducts } from "../../store/products/actions";
import placeholder from "../../assets/images/Decor.webp";
import Spinner from "../Spinner"; // Import the Spinner component

export default function SavingSpotLight() {
  const dispatch = useDispatch();
  const { currentRegion } = useSelector(s => s.config);
  const { isLoading, items } = useSelector(
    (s) => s.products.homeSavingSpotlightProducts
  );
  const differedRegion = useDeferredValue(currentRegion?.region);
  logger("Saving spotlight products ::: ", items);
  const limit = 6;
  const navigate = useNavigate();

  useEffect(() => {
    if (!items?.length || differedRegion)
      dispatch(
        apiGetHomeSavingSpotlightProducts({
          limit: limit,
        })
      );
  }, [dispatch, differedRegion]);

  // Create a fallback component for Suspense
  const LoadingFallback = () => (
    <div className="discover_card px-3">
      <div className="card_top_head d-flex align-items-center justify-content-between">
        <h4>Saving Spotlight</h4>
        <Link to={ROUTES.SAVING_SPOTLIGHT_PRODUCT_LISTING}>View More</Link>
      </div>
      <div className="card_white mt-3">
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <Suspense fallback={<LoadingFallback />}>
          <LoadingFallback />
        </Suspense>
      ) : (
        <div className="discover_card px-3">
          <div className="card_top_head d-flex align-items-center justify-content-between">
            <h4>Saving Spotlight</h4>
            <Link onClick={() => smoothScrollToTop()} to={ROUTES.SAVING_SPOTLIGHT_PRODUCT_LISTING}>View More</Link>
          </div>
          {items?.length ? (
            <div
              className="card_white mt-3 cursor-pointer"
              onClick={() => {
                navigate(
                  `${ROUTES.SAVING_SPOTLIGHT_PRODUCT_LISTING}?topIds=${items[0]._id}`
                );
                smoothScrollToTop();
              }}
            >
              <div className="lowest_price d-flex align-items-center">
                <div className="lowest_img me-2">
                  <img
                    src={items[0]?.featured_image || placeholder}
                    alt=""
                    className="img-fluid rounded"
                  />
                </div>
                <p>Lowest price in 180 days</p>
              </div>
            </div>
          ) : null}
          <BestSalerProduct />
        </div>
      )}
    </>
  );
}
