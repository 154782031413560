import * as Yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import apiClient from "../helpers/apiHelper";
import { AUTH } from "../helpers/urlHelper";
import { logger } from "../helpers/commonHelper";

const verifyWithApi = ({ verify, countryCode = null, value, createError, debouncing, path, type, fieldName, key, id }) => {
    if (verify && value !== debouncing?.lastValue) {
        debouncing.lastValue = value;
        debouncing.lastError = null;
        return new Promise((resolve, reject) => {
            clearTimeout(debouncing?.timeoutId);
            debouncing.timeoutId = setTimeout(async () => {
                try {
                    await apiClient.post(AUTH.VERIFY_MOBILE, {
                        countryCode,
                        [type]: value,
                    }, key, id);
                    resolve(true);
                } catch (error) {
                    debouncing.lastError = { path, message: error?.message || `Failed to verify ${fieldName}, please try again.` };
                    reject(createError(debouncing?.lastError));
                }
            }, 1000);
        });
    }
    else if (value === debouncing?.lastValue && debouncing?.lastError) {
        return createError(debouncing?.lastError);
    }

    return true;
}

Yup.addMethod(Yup.string, "phoneRequired", function ({ required = true, verify = true, fieldName = "Mobile number", key, id }) {
    let debouncing = {
        timeoutId: undefined,
        lastValue: "",
        lastError: null,
    }
    return this.test({
        name: "phoneRequired",
        message: `${fieldName} validation failed`,
        test: async function (value) {
            const { path, createError, options } = this;

            if (!isValidPhoneNumber(`${(options?.context?.countryCode || "") + value}`)) {
                return createError({ path, message: `${fieldName} is invalid` });
            }

            return verifyWithApi({ verify, value, countryCode: options?.context?.countryCode || "", createError, debouncing, path, type: "mobileNumber", fieldName, key, id });
        },
    });
});

export const requiredMobileNumber = ({ verify = true, field = "Phone number", key = null, id = null }) => Yup.string().phoneRequired({ verify, fieldName: field, key, id });