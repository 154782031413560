import React, { useContext, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { apiGetChatRoom, apiGetPreviousChatMessages } from "../../store/chat/actions";
import { clearChatRoom, newMessageReceived } from "../../store/chat/slice";
import { Field, Form, Formik } from "formik";
import SocketContext from "../../socket/SocketContext";
import { logger, timeAgo } from "../../helpers/commonHelper";
import AbsoluteLoader from "../Common/AbsoluteLoader";
import InfiniteScroll from "react-infinite-scroll-component"
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { ICON_FILE } from "../../assets/svg";
import apiClient from "../../helpers/apiHelper";
import { FILE } from "../../helpers/urlHelper";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}


export default function ChatPopup({ setUnReadMessage, show, setShow, productId }) {
  const dispatch = useDispatch();
  const { isLoading, isFetching, chatRoom, product, chatList, profile, hasMoreMessages, senderId, receiverId } = useSelector(s => s.chat);
  const { user, isLogin } = useSelector(s => s.auth);
  const { socket } = useContext(SocketContext);

  const fileInputRef = useRef(null);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (!chatRoom && isLogin) {
      dispatch(apiGetChatRoom({
        data: { productId }, callback: (res) => {
          if (res?.data?._id) {
            socket.emit("onJoinRoom", { roomId: res?.data?._id }, (data) => {
              logger("CHAT_MESSAGE", "onJoinRoom", data)
            });
            setUnReadMessage(res?.unreadMessagesCount || 0);
          }
        }
      }));
    }
    else if (show) {
      setUnReadMessage(0);
      if (chatRoom?._id) {
        socket?.emit("onReadMessage", { chatRoomId: chatRoom?._id }, (data) => {
          console.log("CHAT_PAGE", "onReadMessage", data)
        });
      }
    }
  }, [show, isLogin]);

  useEffect(() => {
    return () => {
      if (chatRoom?._id) {
        socket.emit("onLeaveRoom", { roomId: chatRoom._id }, (data) => {
          logger("CHAT_MESSAGE", "onLeaveRoom", data)
        });
      }
      dispatch(clearChatRoom());
    }
  }, [chatRoom]);

  useEffect(() => {
    if (socket) {
      socket.on("onMessageReceive", (data) => {
        dispatch(newMessageReceived(data));
        logger("CHAT_MESSAGE", "onMessageReceive", data);
        if (!show) {
          setUnReadMessage(prev => prev + 1);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("onMessageReceive");
      }
    }
  }, [socket, show]);

  const handleMessageSend = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    let files = [];
    if (uploadingFiles?.length) {
      setUploading(true);
      files = await Promise.all(uploadingFiles.map(file => {
        return new Promise(async (resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);
          try {
            const res = await apiClient.post(FILE.ADD, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            resolve(res.data?.link);
          } catch (error) {
            reject(error);
          }
        });
      }));
      setUploading(false);
    }

    socket?.emit("onMessageSend", {
      senderId: user?._id,
      receiverId: profile?._id,
      productId,
      message: values?.message,
      chatRoomId: chatRoom?._id,
      files,
    }, (response) => {
      logger("CHAT_MESSAGE", "onMessageSend", response);
      setUploadingFiles([]);
      fileInputRef.current.value = null;
      resetForm();
      setSubmitting(false);
    });
  }

  const handlePreviousChat = () => {
    dispatch(apiGetPreviousChatMessages({
      chatRoomId: chatRoom?._id,
      data: {
        lastMessageId: chatList[0].chatRoomId,
        lastMessageCreatedAt: chatList.at(-1).createdAt,
        limit: 20
      },
      callback: (res) => {
        logger("CHAT_MESSAGE", "handlePreviousChat", res)
      }
    }));
  }

  function handleAcceptedFiles(event) {
    const _files = Array.from(event.target.files); // Convert FileList to Array

    // Filter files by size (less than 5MB)
    const files = _files.filter(file => file.size < 5242880); // 5242880 bytes = 5MB

    // If there are any files that exceed the size limit, show an error
    if (files.length < _files.length) {
      return toast.error("The maximum file size is 5MB.");
    }

    // Add files to the uploading state with preview and formatted size
    setUploadingFiles(oldFiles => [
      ...oldFiles,
      ...files.map(file => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });
      }),
    ]);
  }

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      style={{ minWidth: "600px" }}
      className="add_offcanva"
    >
      {isLoading && <AbsoluteLoader />}
      <Offcanvas.Header>
        <Offcanvas.Title className="w-100">
          <div className="product-chat-drawer w-100">
            <div className="d-flex gap-2 align-items-center justify-content-between mb-2 w-100">
              <div className="d-flex gap-2 align-items-center">
                {!isLoading && (
                  <>
                    <img src={product?.featured_image || ""} alt="Profile Img" />
                    <h4 className="m-0">{profile?.name || ""}</h4>
                  </>
                )}
              </div>
              <button className="border-0 bg-transparent" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 6.827 6.827"
                  style={{
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    imageRendering: "optimizeQuality",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                  }}
                >
                  <defs>
                    <style>
                      {`.str1 { stroke: #212121; stroke-width: .213335; stroke-linecap: round; stroke-linejoin: round; }
          .fil0 { fill: none; }`}
                    </style>
                  </defs>
                  <g id="Layer_x0020_1">
                    <g id="_436668208">
                      <circle
                        id="_436668496"
                        cx="3.413"
                        cy="3.413"
                        r="2.453"
                        stroke="#212121"
                        strokeWidth="0.213335"
                        fill="none"
                      />
                      <path id="_436668520" className="str1" d="M4.64 2.187 2.187 4.64" />
                      <path id="_436668568" className="str1" d="M4.64 4.64 2.187 2.187" />
                    </g>
                  </g>
                  <path className="fil0" d="M0 0h6.827v6.827H0z" />
                </svg>
              </button>
            </div>
            {/* <p className="m-0">{JSON.stringify(product, null, 2)}</p>
            <p className="m-0">{JSON.stringify(profile, null, 2)}</p> */}
          </div>
          <div className="product-price px-2">
            {/* <ProductPricing
              price_tiers={product?.price_tiers}
              defaultPrice={product?.price}
            /> */}
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>

        {!isLoading && (
          <>
            <div className="d-flex flex-column chat-message-footer-wrapper h-100">
              <InfiniteScroll
                dataLength={chatList?.length || 0} //This is important field to render the next data
                next={handlePreviousChat}
                hasMore={hasMoreMessages}
                loader={isFetching && (
                  <div className="px-3">
                    Loading...
                  </div>
                )}
                style={{ display: 'flex', flexDirection: 'column-reverse', background: '#dddddd75' }}
                inverse={true}
                scrollableTarget="scrollableChatDiv"
                endMessage={""}
                className="p-3 rounded-3"
              >
                <ul id="scrollableChatDiv" className={`list-unstyled chat-message-drawer-wrapper ${uploadingFiles?.length > 0 ? "has-file" : ""}`} style={{
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column-reverse"
                }}>
                  {chatList?.length ? chatList?.map((item, index) => (
                    <li className={`${user?._id === item?.senderId ? "msg-right-wrapper" : "msg-left-wrapper"} text-break`} key={index}>
                      {item?.message && <p className='message-text m-0'>{item?.message}
                        <p className={`${user?._id === item?.senderId ? "text-end" : "text-start"} w-100 fs-base my-0`} style={{ color: "#9b9b9b" }}>{timeAgo(item?.createdAt)}</p>
                      </p>}

                      {item?.files?.length > 0 && (
                        <div className="uza-chat-files d-flex flex-column">
                          {item?.files?.map((file, index) => {
                            const isImage = /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(file);

                            return (
                              <a key={index} download={true} href={file} target="_blank" className="chat-file" style={{
                                textDecoration: "none"
                              }}>
                                <div style={{
                                  background: "#ccc",
                                  width: "fit-content",
                                  padding: "3px",
                                  borderRadius: "10px"
                                }}
                                  className="mt-2"
                                >
                                  {isImage ? (
                                    <img src={file} alt={`file-${index}`} className="img-fluid img-thumbnail" />
                                  ) : (
                                    <div className={`file-thumbnail-message  d-flex gap-2 align-items-center flex-column`}>
                                      <ICON_FILE size={30} />
                                      <p className="text-wrap text-dark text-truncate w-100 m-0">{file.split('/').pop()}</p>
                                    </div>
                                  )}

                                  <p className={`${user?._id === item?.senderId ? "text-end" : "text-start"} w-100 fs-base my-0`} style={{ color: "#9b9b9b" }}>{timeAgo(item?.createdAt)}</p>
                                </div>
                              </a>
                            );
                          })}
                        </div>
                      )}
                    </li>
                  )) : <li className="h-100 d-flex flex-column align-items-center justify-content-center w-100">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={150} height={150} x="0" y="0" viewBox="0 0 64 64" fillRule="evenodd" ><g><path d="M25.621 18.547A19.62 19.62 0 0 0 21 18c-9.96 0-18 7.384-18 16.417 0 4.482 2.006 8.758 5.539 11.848l.008.008a.984.984 0 0 1 .337.884l-.001.007c-.252 1.922-.774 5.699-.774 5.699a1.001 1.001 0 0 0 1.553.964l5.258-3.574c.246-.167.552-.216.837-.135l.005.002c1.695.474 3.461.714 5.238.714 6.161 0 11.596-2.83 14.835-7.12 1.932.605 4.006.933 6.165.933 1.894 0 3.776-.258 5.581-.767l.006-.001a.998.998 0 0 1 .839.133l5.612 3.815a1 1 0 0 0 1.553-.964s-.565-4.081-.83-6.096v-.006a.986.986 0 0 1 .338-.888l.009-.007C58.865 36.602 61 32.073 61 27.324 61 17.791 52.512 10 42 10c-6.986 0-13.079 3.441-16.379 8.547zm-1.023 1.82A17.705 17.705 0 0 0 21 20c-8.805 0-16 6.432-16 14.417 0 3.915 1.769 7.641 4.853 10.341a2.981 2.981 0 0 1 1.013 2.666c-.123.938-.31 2.317-.469 3.485l3.4-2.311a2.997 2.997 0 0 1 2.504-.404c1.521.425 3.105.64 4.699.64 5.264 0 9.944-2.295 12.866-5.852C27.436 40.196 23 34.215 23 27.324c0-2.471.57-4.825 1.598-6.957zM25 27.324C25 18.838 32.643 12 42 12s17 6.838 17 15.324c0 4.18-1.896 8.158-5.202 11.031a2.988 2.988 0 0 0-1.019 2.68c.135 1.021.348 2.585.524 3.874l-3.754-2.552a3.001 3.001 0 0 0-2.516-.4 18.57 18.57 0 0 1-5.033.69c-9.357 0-17-6.838-17-15.323zM23 40h-9a1 1 0 0 0 0 2h9a1 1 0 0 0 0-2zm-2-6h-7a1 1 0 0 0 0 2h7a1 1 0 0 0 0-2zm29-9a3.001 3.001 0 0 0 0 6 3.001 3.001 0 0 0 0-6zm-8 0a3.001 3.001 0 0 0 0 6 3.001 3.001 0 0 0 0-6zm-8 0a3.001 3.001 0 0 0 0 6 3.001 3.001 0 0 0 0-6zm-14 3h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zm30-1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-8 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-8 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="#00085f" opacity="1" dataOriginal="#000000"></path></g></svg>
                  </li>}
                </ul>
              </InfiniteScroll>

              <Formik initialValues={{ message: "" }} onSubmit={handleMessageSend}>
                {({ values, isSubmitting }) => (
                  <Form className="d-flex flex-column">
                    {uploadingFiles?.length > 0 ? (
                      <div className="d-flex flex-column flex-wrap position-relative">
                        {isUploading && (
                          <div className="spinner position-absolute" style={{
                            width: "100%",
                            height: "100%",
                            zIndex: 1,
                            background: "rgb(0 96 162 / 11%)",
                            margin: 0,
                            borderRadius: 15,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                            <Spinner color="primary" />
                          </div>
                        )}
                        <div className="d-flex gap-3 align-items-center">
                          {/* Show up to 4 files */}
                          {uploadingFiles?.slice(0, 4).map((file, index) => {
                            const isImage = file?.type.startsWith('image/');
                            return (
                              <div key={index} className="position-relative m-1 upload-imgs-wrapper">
                                {isImage ? (
                                  <img
                                    src={file?.preview}
                                    className="img-fluid img-thumbnail"
                                    alt={file?.name}
                                  />
                                ) : (
                                  <div className="file-thumbnail">
                                    <ICON_FILE size={30} />
                                    <p className="text-break text-truncate w-100 m-0" title={file?.name || ""}>{file?.name}</p>
                                  </div>
                                )}
                              </div>
                            );
                          })}

                          {/* If there are more than 4 files, show a "more" count */}
                          {uploadingFiles?.length > 4 && (
                            <div className="position-relative d-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px' }}>
                              <div className="bg-dark text-white rounded-circle" style={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                +{uploadingFiles.length - 4}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}

                    <div className="d-flex align-items-center gap-3">
                      <Field name="message" className='w-100' />
                      <div className="position-relative upload-btn-wrapper">
                        <input
                          ref={fileInputRef}
                          className="form-control footer-input-wrapper"
                          type="file"
                          multiple
                          accept="image/*, application/pdf"
                          onChange={handleAcceptedFiles}
                        />
                        <div class="position-absolute upload-icon-wrapper"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20px" height="20px" x="0" y="0" viewBox="0 0 512 512.001"><g><path d="M348.945 221.64v-96.894c0-2.773-1.28-5.336-3.093-7.363L237.219 3.309C235.19 1.176 232.309 0 229.429 0H57.196C25.398 0 0 25.93 0 57.73v325.684c0 31.8 25.398 57.305 57.195 57.305h135.953C218.863 483.402 265.605 512 318.852 512c80.886 0 146.941-65.734 146.941-146.727.11-70.75-50.688-129.867-116.848-143.632ZM240.102 37.458l72.882 76.723h-47.273c-14.086 0-25.61-11.63-25.61-25.715ZM57.195 419.375c-19.953 0-35.851-16.008-35.851-35.96V57.73c0-20.062 15.898-36.386 35.851-36.386h161.563v67.12c0 25.93 21.023 47.06 46.953 47.06h61.89v83.34c-3.199-.106-5.761-.427-8.535-.427-37.242 0-71.496 14.301-97.32 36.711H86.223c-5.871 0-10.672 4.801-10.672 10.668 0 5.872 4.8 10.672 10.672 10.672h115.675c-7.578 10.672-13.875 21.344-18.78 33.082H86.222c-5.871 0-10.672 4.801-10.672 10.672 0 5.867 4.8 10.672 10.672 10.672h89.957c-2.668 10.672-4.055 22.516-4.055 34.36 0 19.206 3.734 38.203 10.457 54.21H57.195Zm261.766 71.39c-69.149 0-125.387-56.238-125.387-125.386 0-69.149 56.13-125.387 125.387-125.387 69.254 0 125.383 56.238 125.383 125.387 0 69.148-56.235 125.387-125.383 125.387Zm0 0" fill="#000000" data-original="#000000" class=""></path><path d="M86.223 223.027H194.32c5.871 0 10.672-4.804 10.672-10.672 0-5.87-4.8-10.671-10.672-10.671H86.223c-5.871 0-10.672 4.8-10.672 10.671 0 5.868 4.8 10.672 10.672 10.672ZM326.535 286.625c-2.027-2.133-4.8-3.414-7.789-3.414-2.988 0-5.762 1.281-7.789 3.414l-62.535 67.121c-4.055 4.27-3.734 11.098.535 15.047 4.27 4.055 11.203 3.734 15.258-.531l44.18-47.274v116.528c0 5.87 4.8 10.671 10.671 10.671 5.868 0 10.672-4.8 10.672-10.671V320.988l43.856 47.274c2.136 2.238 4.91 3.414 7.793 3.414 2.558 0 5.12-.961 7.254-2.883 4.27-4.055 4.59-10.777.535-15.047Zm0 0" fill="#000000" data-original="#000000" class=""></path></g></svg></div>
                      </div>
                      {(values?.message?.trim() || uploadingFiles?.length > 0) && <button type="submit" disabled={isSubmitting}>Send</button>}
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}

      </Offcanvas.Body>
    </Offcanvas>
  );
}
