import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../helpers/apiHelper";
import { REVIEW } from "../../helpers/urlHelper";
import { generatePath } from "react-router-dom";

export const apiGetReviews = createAsyncThunk(
  "apiGetReviews",
  async ({ productId, query }, Thunk) => {
    try {
      const res = await apiClient.get(generatePath(REVIEW.LIST, { productId }), { params: query });
      if (res.status === "success") {
        return res?.data;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return Thunk.rejectWithValue(
        error.message || "Something went wrong, please try again later."
      );
    }
  }
);


export const apiAddReview = createAsyncThunk(
  "apiAddReview",
  async ({ productId, data }, Thunk) => {
    try {
      const res = await apiClient.post(generatePath(REVIEW.ADD, { productId }), data);
      if (res.status === "success") {
        return res;
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      return Thunk.rejectWithValue(
        error.message || "Something went wrong, please try again later."
      );
    }
  }
);